export const FEEDBACK_LINK_ANNOTATION_EDIT = 'FEEDBACK_LINK_ANNOTATION_EDIT';
export const FEEDBACK_LINK_ANNOTATION_SCREENSHOT_SET = 'FEEDBACK_LINK_ANNOTATION_SCREENSHOT_SET';
export const FEEDBACK_LINK_ANNOTATION_SELECT = 'FEEDBACK_LINK_ANNOTATION_SELECT';
export const FEEDBACK_LINK_BATCH_ACTION_ANNOTATION_IDS_SET = 'FEEDBACK_LINK_BATCH_ACTION_ANNOTATION_IDS_SET';
export const FEEDBACK_LINK_BATCH_ACTIONS_STATE_TOGGLE = 'FEEDBACK_LINK_BATCH_ACTIONS_STATE_TOGGLE';
export const FEEDBACK_LINK_BATCH_ACTIONS_SET = 'FEEDBACK_LINK_BATCH_ACTIONS_SET';
export const FEEDBACK_LINK_CANVAS_ASSET_SELECT = 'FEEDBACK_LINK_CANVAS_ASSET_SELECT';
export const FEEDBACK_LINK_CANVAS_SELECT = 'FEEDBACK_LINK_CANVAS_SELECT';
export const FEEDBACK_LINK_COMMENTING_DISABLE = 'FEEDBACK_LINK_COMMENTING_DISABLE';
export const FEEDBACK_LINK_COMMENTING_ENABLE = 'FEEDBACK_LINK_COMMENTING_ENABLE';
export const FEEDBACK_LINK_COMMENTS_BY_PAGE_SORT = 'FEEDBACK_LINK_COMMENTS_BY_PAGE_SORT';
export const FEEDBACK_LINK_COMMENTS_BY_OLDEST_SORT = 'FEEDBACK_LINK_COMMENTS_BY_OLDEST_SORT';
export const FEEDBACK_LINK_COMMENTS_BY_RECENCY_SORT = 'FEEDBACK_LINK_COMMENTS_BY_RECENCY_SORT';
export const FEEDBACK_LINK_COMMENTS_STATUS_SHOW = 'FEEDBACK_LINK_COMMENTS_STATUS_SHOW';
export const FEEDBACK_LINK_DESIGN_PINS_VISIBILITY_TOGGLE = 'FEEDBACK_LINK_DESIGN_PINS_VISIBILITY_TOGGLE';
export const FEEDBACK_LINK_DESIGN_ZOOM_AUTO_DISABLE = 'FEEDBACK_LINK_DESIGN_ZOOM_AUTO_DISABLE';
export const FEEDBACK_LINK_DESIGN_ZOOM_FIT_SET = 'FEEDBACK_LINK_DESIGN_ZOOM_FIT_SET';
export const FEEDBACK_LINK_DESIGN_ZOOM_PERCENTAGE_SET = 'FEEDBACK_LINK_DESIGN_ZOOM_PERCENTAGE_SET';
export const FEEDBACK_LINK_DEVICE_TYPE_SET = 'FEEDBACK_LINK_DEVICE_TYPE_SET';
export const FEEDBACK_LINK_EXPORT_SERVICE_SELECT = 'FEEDBACK_LINK_EXPORT_SERVICE_SELECT';
export const FEEDBACK_LINK_FILTERED_ANNOTATIONS_SET = 'FEEDBACK_LINK_FILTERED_ANNOTATIONS_SET';
export const FEEDBACK_LINK_FILTERS_SET = 'FEEDBACK_LINK_FILTERS_SET';
export const FEEDBACK_LINK_MARK_USER_AS_PRESENT = 'FEEDBACK_LINK_MARK_USER_AS_PRESENT';
export const FEEDBACK_LINK_MODAL_EXPORT_ANNOTATIONS_HIDE = 'FEEDBACK_LINK_MODAL_EXPORT_ANNOTATIONS_HIDE';
export const FEEDBACK_LINK_MODAL_EXPORT_ANNOTATIONS_SHOW = 'FEEDBACK_LINK_MODAL_EXPORT_ANNOTATIONS_SHOW';
export const FEEDBACK_LINK_MODAL_FILE_ATTACHMENT_HIDE = 'FEEDBACK_LINK_MODAL_FILE_ATTACHMENT_HIDE';
export const FEEDBACK_LINK_MODAL_FILE_ATTACHMENT_SHOW = 'FEEDBACK_LINK_MODAL_FILE_ATTACHMENT_SHOW';
export const FEEDBACK_LINK_MODAL_GUEST_LOGIN_HIDE = 'FEEDBACK_LINK_MODAL_GUEST_LOGIN_HIDE';
export const FEEDBACK_LINK_MODAL_GUEST_LOGIN_SHOW = 'FEEDBACK_LINK_MODAL_GUEST_LOGIN_SHOW';
export const FEEDBACK_LINK_MODAL_INTRO_HIDE = 'FEEDBACK_LINK_MODAL_INTRO_HIDE';
export const FEEDBACK_LINK_MODAL_INTRO_SHOW = 'FEEDBACK_LINK_MODAL_INTRO_SHOW';
export const FEEDBACK_LINK_MODAL_NEW_ANNOTATION_HIDE = 'FEEDBACK_LINK_MODAL_NEW_ANNOTATION_HIDE';
export const FEEDBACK_LINK_MODAL_NEW_ANNOTATION_SHOW = 'FEEDBACK_LINK_MODAL_NEW_ANNOTATION_SHOW';
export const FEEDBACK_LINK_MODAL_NEW_REVISION_HIDE = 'FEEDBACK_LINK_MODAL_NEW_REVISION_HIDE';
export const FEEDBACK_LINK_MODAL_NEW_REVISION_SHOW = 'FEEDBACK_LINK_MODAL_NEW_REVISION_SHOW';
export const FEEDBACK_LINK_MODAL_REVIEW_CANVAS_HIDE = 'FEEDBACK_LINK_MODAL_REVIEW_CANVAS_HIDE';
export const FEEDBACK_LINK_MODAL_REVIEW_CANVAS_SHOW = 'FEEDBACK_LINK_MODAL_REVIEW_CANVAS_SHOW';
export const FEEDBACK_LINK_NEW_ANNOTATION_COMMENT_SET = 'FEEDBACK_LINK_NEW_ANNOTATION_COMMENT_SET';
export const FEEDBACK_LINK_NEW_ANNOTATION_DATA_UPDATE = 'FEEDBACK_LINK_NEW_ANNOTATION_DATA_UPDATE';
export const FEEDBACK_LINK_NEW_ANNOTATION_MODE_SET = 'FEEDBACK_LINK_NEW_ANNOTATION_MODE_SET';
export const FEEDBACK_LINK_PAGE_NAVIGATE = 'FEEDBACK_LINK_PAGE_NAVIGATE';
export const FEEDBACK_LINK_PAGE_NAVIGATE_START = 'FEEDBACK_LINK_PAGE_NAVIGATE_START';
export const FEEDBACK_LINK_PAGE_NAVIGATE_STOP = 'FEEDBACK_LINK_PAGE_NAVIGATE_STOP';
export const FEEDBACK_LINK_PATH_SET = 'FEEDBACK_LINK_PATH_SET';
export const FEEDBACK_LINK_PRODUCT_TOUR_START = 'FEEDBACK_LINK_PRODUCT_TOUR_START';
export const FEEDBACK_LINK_REPLY_EDIT = 'FEEDBACK_LINK_REPLY_EDIT';
export const FEEDBACK_LINK_REVISION_SELECT = 'FEEDBACK_LINK_REVISION_SELECT';
export const FEEDBACK_LINK_SEARCH_ACTIVE_TOGGLE = 'FEEDBACK_LINK_SEARCH_ACTIVE_TOGGLE';
export const FEEDBACK_LINK_SEARCH_TERM_SET = 'FEEDBACK_LINK_SEARCH_TERM_SET';
export const FEEDBACK_LINK_SIDEBAR_CLOSE = 'FEEDBACK_LINK_SIDEBAR_CLOSE';
export const FEEDBACK_LINK_SIDEBAR_OPEN = 'FEEDBACK_LINK_SIDEBAR_OPEN';
export const FEEDBACK_LINK_USER_AGENT_SET = 'FEEDBACK_LINK_USER_AGENT_SET';
export const FEEDBACK_LINK_VIEW_MODE_SET = 'FEEDBACK_LINK_VIEW_MODE_SET';
export const MODAL_CANVAS_NOTIFICATIONS_HIDE = 'MODAL_CANVAS_NOTIFICATIONS_HIDE';
export const MODAL_CANVAS_NOTIFICATIONS_SHOW = 'MODAL_CANVAS_NOTIFICATIONS_SHOW';
export const MODAL_SHARE_CANVAS_HIDE = 'MODAL_SHARE_CANVAS_HIDE';
export const MODAL_SHARE_CANVAS_SHOW = 'MODAL_SHARE_CANVAS_SHOW';
export const MODAL_TRANSFER_CANVAS_OWNERSHIP_HIDE = 'MODAL_TRANSFER_CANVAS_OWNERSHIP_HIDE';
export const MODAL_TRANSFER_CANVAS_OWNERSHIP_SHOW = 'MODAL_TRANSFER_CANVAS_OWNERSHIP_SHOW';
