import API from 'lib/api';
import { getInitialVisitData } from 'lib/initial-visit-tracking';
import * as analytics from 'lib/analytics';
import * as affiliate from 'lib/affiliate';

import {
    AUTH_USER_LOGIN,
    AUTH_USER_LOGOUT,
    AUTH_USER_UPDATE,
    AUTH_API_TOKEN_SET,
    AUTH_API_IMPERSONATE_USER_ID_SET,
    AUTH_API_LOGIN_STATUS_SET
} from './types';

/**
 * Sets logged-in user. Type refers to the type of user login event (e.g. signup or login or neither.)
 *
 * @export
 * @param {*} [user=null]
 * @param {null | 'signup' | 'login'} [type=null]
 * @return {*}
 */
export function setLoggedInUser(user = null, type = null) {
    return (dispatch) => {
        analytics.identify(user, type);

        return dispatch({
            type: AUTH_USER_LOGIN,
            payload: user
        });
    };
}

export function updateLoggedInUser(user) {
    return {
        type: AUTH_USER_UPDATE,
        payload: { user }
    };
}

export function setAPIToken(apiToken = null) {
    return {
        type: AUTH_API_TOKEN_SET,
        payload: { apiToken }
    };
}

export function setImpersonatedUserId(userId = null) {
    return {
        type: AUTH_API_IMPERSONATE_USER_ID_SET,
        payload: { userId }
    };
}

export function setLoginStatus(loginStatus = null) {
    return {
        type: AUTH_API_LOGIN_STATUS_SET,
        payload: { loginStatus }
    };
}

export function signup({ email, name, password, teamName = null, coupon = null, plan = null, inviteCode = null }) {
    return (dispatch, getState) => {
        const initialVisitData = getInitialVisitData();
        return API.signup(
            { email, name, password, coupon, teamName, plan, inviteCode, initialVisitData },
            getState().auth
        ).then((user) => {
            dispatch(setLoggedInUser(user, 'signup'));
            analytics.intercomIdentify(user); // Register user with Intercom
            analytics.signup(user); // Register signup with analytics
            affiliate.registerSignup(user); // Register signup with PartnerStack

            if (inviteCode) {
                analytics.teamInviteResponse(true, 'signup');
            }

            return user;
        });
    };
}

export function login({ email, password }) {
    return (dispatch, getState) =>
        API.login({ email, password }, getState().auth).then((user) => {
            dispatch(setLoggedInUser(user, 'login'));
            analytics.login(user);
            return user;
        });
}

export function loginGuest({ email, name }) {
    return (dispatch, getState) => {
        const { auth, canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        return API.loginGuest({ email, name }, auth).then((user) => {
            analytics.guestLogin(user, canvas);
            return dispatch(setLoggedInUser(user));
        });
    };
}

export function logout() {
    return (dispatch, getState) =>
        API.logout(getState().auth).then(() =>
            dispatch({
                type: AUTH_USER_LOGOUT,
                payload: null
            })
        );
}

/**
 * Res is used here to propagate some cookies from the API to the frontend.
 * Needed because of our cookie-based auth that is shared across server-side / browser-based rendering.
 *
 * @export
 * @param {*} [res=null]
 * @returns
 */
export function getLoggedInUser(res = null) {
    return (dispatch, getState) =>
        API.call('/sessions/me', 'get', null, getState().auth, res)
            .then((user) => dispatch(setLoggedInUser(user)))
            .catch((err) => {
                if (err.name !== 'NotLoggedIn') {
                    throw err;
                }

                return dispatch(setLoggedInUser());
            });
}

export function fetchSSOLoginUrl(emailDomain, nextUrl) {
    return (_dispatch, getState) => {
        let url = `/sso-login-url?emailDomain=${encodeURIComponent(emailDomain)}`;
        if (nextUrl) {
            url += `&nextUrl=${encodeURIComponent(nextUrl)}`;
        }

        return API.call(url, 'get', null, getState().auth);
    };
}

export function changePassword({ currentPassword, newPassword }) {
    return (_dispatch, getState) =>
        API.call('/users/me/password', 'put', { currentPassword, newPassword }, getState().auth);
}

export function updateCustomer({ shouldEmailReceipts, billingEmail }) {
    return (dispatch, getState) =>
        API.call('/users/me/customer', 'put', { shouldEmailReceipts, billingEmail }, getState().auth).then((user) => {
            dispatch(setLoggedInUser(user));
            return user;
        });
}

export function getUserAPIToken() {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call('/users/me/token', 'get', null, auth).then(({ token }) => {
            dispatch(setLoggedInUser({ ...auth.user, token }));
            return token;
        });
    };
}

export function generateUserAPIToken() {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call('/users/me/token', 'post', null, auth).then(({ token }) => {
            dispatch(setLoggedInUser({ ...auth.user, token }));
            return token;
        });
    };
}

export function updateProfile({ name, email,emailOn,clientEmailSettings, myEmailSettings, emailSettings, signupSurvey, offboardingSurvey }) {
    return (dispatch, getState) =>
        API.call(
            '/users/me',
            'put',
            { name, email,emailOn, myEmailSettings, clientEmailSettings, emailSettings, signupSurvey, offboardingSurvey },
            getState().auth
        ).then((user) => dispatch(setLoggedInUser(user)));
}

export function updateAvatar(avatar) {
    return (dispatch, getState) =>
        API.upload('/users/me/avatar', 'put', { avatar }, getState().auth).then((user) =>
            dispatch(setLoggedInUser(user))
        );
}

export function fetchSSOSettings(teamId) {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/teams/${teamId}/sso-settings`, 'get', null, auth).then((ssoSettings) => {
            const user = { ...auth.user, Team: { ...auth.user.Team, SSOConfig: ssoSettings } };
            return dispatch(setLoggedInUser(user));
        });
    };
}

export function createOrUpdateSSOSettings(ssoSettings) {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/teams/${ssoSettings.teamId}/sso-settings`, 'post', ssoSettings, auth).then((ssoSettings) => {
            const user = { ...auth.user, Team: { ...auth.user.Team, SSOConfig: ssoSettings } };
            return dispatch(setLoggedInUser(user));
        });
    };
}
