import {
    FEEDBACK_LINK_ANNOTATION_EDIT,
    FEEDBACK_LINK_ANNOTATION_SCREENSHOT_SET,
    FEEDBACK_LINK_ANNOTATION_SELECT,
    FEEDBACK_LINK_BATCH_ACTIONS_STATE_TOGGLE,
    FEEDBACK_LINK_BATCH_ACTION_ANNOTATION_IDS_SET,
    FEEDBACK_LINK_BATCH_ACTIONS_SET,
    FEEDBACK_LINK_CANVAS_ASSET_SELECT,
    FEEDBACK_LINK_CANVAS_SELECT,
    FEEDBACK_LINK_COMMENTING_DISABLE,
    FEEDBACK_LINK_COMMENTING_ENABLE,
    FEEDBACK_LINK_COMMENTS_BY_PAGE_SORT,
    FEEDBACK_LINK_COMMENTS_BY_OLDEST_SORT,
    FEEDBACK_LINK_COMMENTS_BY_RECENCY_SORT,
    FEEDBACK_LINK_COMMENTS_STATUS_SHOW,
    FEEDBACK_LINK_DESIGN_PINS_VISIBILITY_TOGGLE,
    FEEDBACK_LINK_DESIGN_ZOOM_AUTO_DISABLE,
    FEEDBACK_LINK_DESIGN_ZOOM_FIT_SET,
    FEEDBACK_LINK_DESIGN_ZOOM_PERCENTAGE_SET,
    FEEDBACK_LINK_DEVICE_TYPE_SET,
    FEEDBACK_LINK_EXPORT_SERVICE_SELECT,
    FEEDBACK_LINK_FILTERED_ANNOTATIONS_SET,
    FEEDBACK_LINK_FILTERS_SET,
    FEEDBACK_LINK_MARK_USER_AS_PRESENT,
    FEEDBACK_LINK_MODAL_EXPORT_ANNOTATIONS_HIDE,
    FEEDBACK_LINK_MODAL_EXPORT_ANNOTATIONS_SHOW,
    FEEDBACK_LINK_MODAL_FILE_ATTACHMENT_HIDE,
    FEEDBACK_LINK_MODAL_FILE_ATTACHMENT_SHOW,
    FEEDBACK_LINK_MODAL_GUEST_LOGIN_HIDE,
    FEEDBACK_LINK_MODAL_GUEST_LOGIN_SHOW,
    FEEDBACK_LINK_MODAL_INTRO_HIDE,
    FEEDBACK_LINK_MODAL_INTRO_SHOW,
    FEEDBACK_LINK_MODAL_NEW_ANNOTATION_HIDE,
    FEEDBACK_LINK_MODAL_NEW_ANNOTATION_SHOW,
    FEEDBACK_LINK_MODAL_NEW_REVISION_HIDE,
    FEEDBACK_LINK_MODAL_NEW_REVISION_SHOW,
    FEEDBACK_LINK_MODAL_REVIEW_CANVAS_HIDE,
    FEEDBACK_LINK_MODAL_REVIEW_CANVAS_SHOW,
    FEEDBACK_LINK_NEW_ANNOTATION_COMMENT_SET,
    FEEDBACK_LINK_NEW_ANNOTATION_DATA_UPDATE,
    FEEDBACK_LINK_NEW_ANNOTATION_MODE_SET,
    FEEDBACK_LINK_PAGE_NAVIGATE_START,
    FEEDBACK_LINK_PAGE_NAVIGATE_STOP,
    FEEDBACK_LINK_PAGE_NAVIGATE,
    FEEDBACK_LINK_PATH_SET,
    FEEDBACK_LINK_PRODUCT_TOUR_START,
    FEEDBACK_LINK_REPLY_EDIT,
    FEEDBACK_LINK_REVISION_SELECT,
    FEEDBACK_LINK_SEARCH_ACTIVE_TOGGLE,
    FEEDBACK_LINK_SEARCH_TERM_SET,
    FEEDBACK_LINK_SIDEBAR_CLOSE,
    FEEDBACK_LINK_SIDEBAR_OPEN,
    FEEDBACK_LINK_USER_AGENT_SET,
    FEEDBACK_LINK_VIEW_MODE_SET,
    MODAL_SHARE_CANVAS_HIDE,
    MODAL_SHARE_CANVAS_SHOW
} from './types';

const initialState = {
    batchActionAnnotationIds: [],
    batchActions: {},
    batchActionsActive: false,
    canvasAssetId: null,
    canvasAssetPageIndex: null, // Used for PDFs
    canvasId: null,

    commentingEnabled: true,
    deviceType: 'desktop',
    editingAnnotationId: null,
    editingReplyId: null,
    filteredAnnotationIds: [],
    filters: {},
    fullUrl: '',
    isExportAnnotationsModalOpen: false,
    isGuestLoginModalOpen: false,
    isIntroModalOpen: false,
    isNewAnnotationModalOpen: false,
    isNewRevisionModalOpen: false,
    newAnnotationComment: '',
    newAnnotationData: {},
    newAnnotationMode: null,
    pageNavigating: false,
    path: '',
    productTourStarted: false,
    reviewCanvasId: null,
    revisionId: null,
    search: '',
    searchActive: false,
    selectedAnnotationId: null,
    selectedAnnotationMetadata: {},
    selectedFileAttachmentId: null,
    serviceToExportTo: null,
    showCommentsByStatus: ['active', 'in-progress', 'in-review'],
    showDesignPins: true,
    showScreenshotAnnotationId: null,
    sidebarOpen: false,
    sortBy: 'page',
    urlToNavigateTo: null,
    userAgent: '',
    usersPresent: {},
    usersPresentWithTimestamps: {},
    viewMode: 'desktop',
    viewWidth: 375,
    zoomIsAuto: true,
    zoomPercentage: 100,
    zoomToFit: null
};

const feedbackLink = (state = initialState, action) => {
    switch (action.type) {
        case FEEDBACK_LINK_CANVAS_SELECT: {
            return { ...state, canvasId: action.payload.canvasId };
        }
        case FEEDBACK_LINK_CANVAS_ASSET_SELECT: {
            return {
                ...state,
                canvasAssetId: action.payload.canvasAssetId,
                canvasAssetPageIndex: action.payload.canvasAssetPageIndex
            };
        }
        case FEEDBACK_LINK_ANNOTATION_SELECT: {
            return {
                ...state,
                selectedAnnotationId: action.payload.annotationId,
                selectedAnnotationMetadata: {
                    currentIndex: action.payload.currentIndex,
                    nextAnnotationId: action.payload.nextAnnotationId,
                    numAnnotationsInList: action.payload.numAnnotationsInList,
                    previousAnnotationId: action.payload.previousAnnotationId
                }
            };
        }
        case FEEDBACK_LINK_COMMENTING_ENABLE: {
            return { ...state, commentingEnabled: true };
        }
        case FEEDBACK_LINK_COMMENTING_DISABLE: {
            return { ...state, commentingEnabled: false };
        }
        case FEEDBACK_LINK_SIDEBAR_OPEN: {
            return { ...state, sidebarOpen: true };
        }
        case FEEDBACK_LINK_SIDEBAR_CLOSE: {
            return { ...state, sidebarOpen: false };
        }
        case FEEDBACK_LINK_COMMENTS_STATUS_SHOW: {
            return { ...state, showCommentsByStatus: action.payload.statuses };
        }
        case FEEDBACK_LINK_COMMENTS_BY_RECENCY_SORT: {
            return { ...state, sortBy: 'recency' };
        }
        case FEEDBACK_LINK_COMMENTS_BY_OLDEST_SORT: {
            return { ...state, sortBy: 'oldest' };
        }
        case FEEDBACK_LINK_COMMENTS_BY_PAGE_SORT: {
            return { ...state, sortBy: 'page' };
        }
        case FEEDBACK_LINK_PATH_SET: {
            return { ...state, path: action.payload.path, fullUrl: action.payload.fullUrl };
        }
        case FEEDBACK_LINK_ANNOTATION_EDIT: {
            return { ...state, editedAnnotationId: action.payload.annotationId };
        }
        case FEEDBACK_LINK_REPLY_EDIT: {
            return { ...state, editedReplyId: action.payload.replyId };
        }
        case FEEDBACK_LINK_MODAL_INTRO_HIDE: {
            return { ...state, isIntroModalOpen: false };
        }
        case FEEDBACK_LINK_MODAL_INTRO_SHOW: {
            return { ...state, isIntroModalOpen: true };
        }
        case FEEDBACK_LINK_MODAL_GUEST_LOGIN_HIDE: {
            return { ...state, isGuestLoginModalOpen: false };
        }
        case FEEDBACK_LINK_MODAL_GUEST_LOGIN_SHOW: {
            return { ...state, isGuestLoginModalOpen: true };
        }
        case FEEDBACK_LINK_MODAL_NEW_ANNOTATION_SHOW: {
            return {
                ...state,
                isNewAnnotationModalOpen: true,
                batchActionAnnotationIds: [],
                newAnnotationData: { ...action.payload.data }
            };
        }
        case FEEDBACK_LINK_MODAL_NEW_ANNOTATION_HIDE: {
            return {
                ...state,
                isNewAnnotationModalOpen: false,
                newAnnotationData: {},
                newAnnotationMode: null
            };
        }
        case FEEDBACK_LINK_NEW_ANNOTATION_DATA_UPDATE: {
            return {
                ...state,
                newAnnotationData: { ...state.newAnnotationData, ...action.payload.data }
            };
        }
        case FEEDBACK_LINK_NEW_ANNOTATION_MODE_SET: {
            return { ...state, newAnnotationMode: action.payload.mode };
        }
        case FEEDBACK_LINK_NEW_ANNOTATION_COMMENT_SET: {
            return { ...state, newAnnotationComment: action.payload.comment };
        }
        case FEEDBACK_LINK_MODAL_EXPORT_ANNOTATIONS_SHOW: {
            return { ...state, isExportAnnotationsModalOpen: true, serviceToExportTo: null };
        }
        case FEEDBACK_LINK_MODAL_EXPORT_ANNOTATIONS_HIDE: {
            return { ...state, isExportAnnotationsModalOpen: false };
        }
        case FEEDBACK_LINK_MODAL_NEW_REVISION_SHOW: {
            return { ...state, isNewRevisionModalOpen: true };
        }
        case FEEDBACK_LINK_MODAL_NEW_REVISION_HIDE: {
            return { ...state, isNewRevisionModalOpen: false };
        }
        case FEEDBACK_LINK_REVISION_SELECT: {
            return { ...state, revisionId: action.payload.revisionId };
        }
        case FEEDBACK_LINK_DESIGN_ZOOM_PERCENTAGE_SET: {
            return { ...state, zoomPercentage: action.payload.zoomPercentage };
        }
        case FEEDBACK_LINK_DESIGN_ZOOM_FIT_SET: {
            return { ...state, zoomToFit: action.payload.zoomToFit };
        }
        case FEEDBACK_LINK_DESIGN_ZOOM_AUTO_DISABLE: {
            return { ...state, zoomIsAuto: false };
        }
        case FEEDBACK_LINK_DESIGN_PINS_VISIBILITY_TOGGLE: {
            return { ...state, showDesignPins: action.payload.visibilityState };
        }
        case FEEDBACK_LINK_SEARCH_ACTIVE_TOGGLE: {
            return { ...state, searchActive: action.payload.searchState };
        }
        case FEEDBACK_LINK_EXPORT_SERVICE_SELECT: {
            return { ...state, serviceToExportTo: action.payload.service };
        }
        case FEEDBACK_LINK_ANNOTATION_SCREENSHOT_SET: {
            return { ...state, showScreenshotAnnotationId: action.payload.annotationId };
        }
        case FEEDBACK_LINK_MARK_USER_AS_PRESENT: {
            const usersPresentWithTimestamps = { ...state.usersPresentWithTimestamps };
            const { usersPresent } = state;
            let listChanged = false;

            // Note: usersPresentWithTimestamps is for behind-the-scenes calcs, while usersPresent is meant for rendering
            // This is because usersPresentWithTimestamps updates very frequently, so it causes unneeded rendering.
            Object.keys(usersPresentWithTimestamps).forEach((userId) => {
                // Remove user if we haven't seen from them in 30 seconds
                if (Date.now() - usersPresentWithTimestamps[userId].timestamp > 30000) {
                    delete usersPresentWithTimestamps[userId];
                    listChanged = true;
                }
            });

            if (!usersPresentWithTimestamps[action.payload.userId]) {
                listChanged = true;
            }

            usersPresentWithTimestamps[action.payload.userId] = action.payload;

            return {
                ...state,
                usersPresentWithTimestamps,
                usersPresent: listChanged ? usersPresentWithTimestamps : usersPresent
            };
        }
        case FEEDBACK_LINK_USER_AGENT_SET: {
            return { ...state, userAgent: action.payload.userAgent };
        }
        case FEEDBACK_LINK_SEARCH_TERM_SET: {
            return { ...state, search: action.payload.search };
        }
        case FEEDBACK_LINK_FILTERS_SET: {
            return { ...state, filters: action.payload.filters };
        }
        case FEEDBACK_LINK_BATCH_ACTIONS_STATE_TOGGLE: {
            return { ...state, batchActionsActive: action.payload.batchActionsState };
        }
        case FEEDBACK_LINK_BATCH_ACTIONS_SET: {
            return { ...state, batchActions: action.payload.batchActions };
        }
        case FEEDBACK_LINK_FILTERED_ANNOTATIONS_SET: {
            return { ...state, filteredAnnotationIds: action.payload.annotationIds };
        }
        case FEEDBACK_LINK_VIEW_MODE_SET: {
            return {
                ...state,
                viewMode: action.payload.viewMode,
                viewWidth: action.payload.viewWidth ? action.payload.viewWidth : state.viewWidth
            };
        }
        case FEEDBACK_LINK_DEVICE_TYPE_SET: {
            return { ...state, deviceType: action.payload.deviceType };
        }
        case FEEDBACK_LINK_PAGE_NAVIGATE_START: {
            return { ...state, pageNavigating: true };
        }
        case FEEDBACK_LINK_PAGE_NAVIGATE_STOP: {
            return { ...state, pageNavigating: false };
        }
        case FEEDBACK_LINK_PAGE_NAVIGATE: {
            return { ...state, urlToNavigateTo: action.payload.url };
        }
        case FEEDBACK_LINK_MODAL_FILE_ATTACHMENT_SHOW: {
            return { ...state, selectedFileAttachmentId: action.payload.fileAttachmentId };
        }
        case FEEDBACK_LINK_MODAL_FILE_ATTACHMENT_HIDE: {
            return { ...state, selectedFileAttachmentId: null };
        }
        case FEEDBACK_LINK_PRODUCT_TOUR_START: {
            return { ...state, productTourStarted: true };
        }
        case FEEDBACK_LINK_MODAL_REVIEW_CANVAS_HIDE: {
            return { ...state, reviewCanvasId: null };
        }
        case FEEDBACK_LINK_MODAL_REVIEW_CANVAS_SHOW: {
            return { ...state, reviewCanvasId: action.payload.canvasId };
        }
        case FEEDBACK_LINK_BATCH_ACTION_ANNOTATION_IDS_SET: {
            return { ...state, batchActionAnnotationIds: action.payload.annotationIds };
        }
        case MODAL_SHARE_CANVAS_HIDE: {
            return { ...state, shareModalCanvasId: null };
        }
        case MODAL_SHARE_CANVAS_SHOW: {
            return { ...state, shareModalCanvasId: action.payload.canvasId };
        }
        default:
            return state;
    }
};

export default feedbackLink;
