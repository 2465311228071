import { NotificationManager as notifications } from 'react-notifications';
import qs from 'query-string';
import API from 'lib/api';
import * as analytics from 'lib/analytics';
import { CANVASES_ADD, CANVASES_REMOVE, CANVASES_UPDATE } from './types';
import { setDashboardCanvases, addDashboardCanvases, removeDashboardCanvases } from '../dashboard-canvases/actions';

export function addCanvases(canvases, isInitialLoad = false) {
    return {
        type: CANVASES_ADD,
        payload: { canvases, isInitialLoad }
    };
}

export function removeCanvases(canvases) {
    return {
        type: CANVASES_REMOVE,
        payload: { canvases }
    };
}

export function updateCanvases(canvases) {
    return (dispatch, getState) => {
        const state = getState();

        const prevCanvases = {};
        canvases.forEach((canvas) => {
            prevCanvases[canvas.id] = state.canvases[canvas.id];
        });

        dispatch({
            type: CANVASES_UPDATE,
            payload: { canvases, prevCanvases }
        });
    };
}

export function getDashboardCanvases(filters = {}, pagination = {}) {
    return async (dispatch, getState) => {
        const { auth } = getState();
        let path = '/users/me/canvases';

        const query = {};

        if (filters && Object.keys(filters).length > 0) {
            query.filters = JSON.stringify(filters);
        }

        if (pagination) {
            query.offset = pagination.offset;
            query.limit = pagination.limit;
        }

        if (Object.keys(query).length > 0) {
            path = `${path}?${qs.stringify(query)}`;
        }

        const canvases = await API.call(path, 'get', null, auth);
        dispatch(addCanvases(canvases, true));

        if (pagination) {
            dispatch(addDashboardCanvases(canvases, 'end'));
        } else {
            dispatch(setDashboardCanvases(canvases));
        }
        return canvases;
    };
}

export function getArchivedCanvases(filters = {}, pagination = {}) {
    return getDashboardCanvases({ ...filters, archived: true }, pagination);
}

export function createCanvas(data) {
    return (dispatch, getState) =>
        API.call('/canvases', 'post', data, getState().auth)
            .then((canvas) => {
                analytics.createCanvas(canvas);
                dispatch(addCanvases([canvas]));
                dispatch(addDashboardCanvases([canvas]));
                return canvas;
            })
            .catch((err) => {
                analytics.failedCanvas(err.message, data.localUrl);
                throw err;
            });
}

export function deleteCanvas(canvasId) {
    return (dispatch, getState) =>
        API.call(`/canvases/${canvasId}`, 'delete', null, getState().auth).then((canvas) => {
            analytics.deleteCanvas(canvas);
            dispatch(removeDashboardCanvases([canvas]));
            return dispatch(removeCanvases([canvas]));
        });
}

export function modifyCanvas(data) {
    return (dispatch, getState) =>
        API.call(`/canvases/${data.id}`, 'put', data, getState().auth).then((canvas) =>
            dispatch(updateCanvases([canvas]))
        );
}

export function shareCanvas(canvasId, emails, message = null, source = null) {
    return (_dispatch, getState) => {
        const { canvases, auth } = getState();
        const canvas = canvases[canvasId];

        return API.call(`/canvases/${canvasId}/share`, 'post', { emails, message }, auth).then(() => {
            analytics.shareCanvas(canvas, 'email', emails.length, message, source);
            notifications.success(
                `${emails.map((email) => email.email).join(', ')}.`,
                `Invite${emails.length === 1 ? '' : 's'} sent to`,
                5000
            );
        });
    };
}

export function getCanvasBySubdomain(subdomain, password = null) {
    return (dispatch, getState) =>
        API.call(
            `/canvases/${subdomain}/subdomain${password ? `?password=${password}` : ''}`,
            'get',
            null,
            getState().auth
        ).then((canvas) => dispatch(addCanvases([canvas])));
}

/**
 * Refactor backend API? Might make more sense change API path to be under /canvases/* path, instead of users.
 *
 * @export
 * @param {*} { canvasId }
 * @returns
 */
export function disableCanvasAutoExport({ canvasId }) {
    return (dispatch, getState) =>
        API.call(
            '/users/me/accounts/auto-sync',
            'delete',
            {
                canvasId
            },
            getState().auth
        ).then((canvas) => dispatch(updateCanvases([canvas])));
}

/**
 * Refactor backend API? Might make more sense change API path to be under /canvases/* path, instead of users.
 *
 * @export
 * @param {*} { canvasId, workspaceId, projectId, sectionId, taskId }
 * @returns
 */
export function setupCanvasAutoExportToAsana({ canvasId, workspaceId, projectId, sectionId, taskId, syncStatus }) {
    return (dispatch, getState) =>
        API.call(
            '/users/me/accounts/asana/auto-sync',
            'post',
            {
                canvasId,
                projectId,
                workspaceId,
                taskId,
                sectionId: sectionId === 'none' ? null : sectionId,
                syncStatus
            },
            getState().auth
        ).then((canvas) => dispatch(updateCanvases([canvas])));
}

/**
 * Refactor backend API? Might make more sense change API path to be under /canvases/* path, instead of users.
 *
 * @export
 * @param {*} { canvasId, teamId, spaceId, listId, taskId }
 * @returns
 */
export function setupCanvasAutoExportToClickup({ canvasId, spaceId, listId, taskId, syncStatus, status }) {
    return (dispatch, getState) =>
        API.call(
            '/users/me/accounts/clickup/auto-sync',
            'post',
            {
                canvasId,
                spaceId,
                listId,
                taskId,
                syncStatus,
                status
            },
            getState().auth
        ).then((canvas) => dispatch(updateCanvases([canvas])));
}
