import { createSelector } from 'reselect';
import pathsAreEqual from 'lib/paths-are-equal';
import getAnnotationPath from 'lib/get-annotation-path';
import parseMentions from 'lib/parse-mentions';
import flags from 'lib/flags';

const getUser = (state) => state.auth.user;
const getAnnotationEvents = (state) => state.events;
const getAnnotations = (state) => state.annotations;
const getSelectedRevisionId = (state) => state.ui.feedbackLink.revisionId;

export const getAnnotationEventsPerAnnotationAndCount = createSelector(
    [getAnnotationEvents, getAnnotations, getSelectedRevisionId],
    (events, annotations, selectedRevisionId) => {
        const annotationEvents = {};
        const numAnnotationEventsByStatus = {};
        let numAnnotationEvents = 0;

        // Create map of annotationId to events
        Object.keys(events).forEach((key) => {
            const event = events[key];

            const { annotationId } = event.data;

            if (!annotationId) {
                return;
            }

            const annotation = annotations[annotationId];

            if (selectedRevisionId && annotation && annotation.CanvasRevisionId !== selectedRevisionId) {
                return;
            }

            if (!annotationEvents[annotationId]) {
                annotationEvents[annotationId] = [];
            }

            annotationEvents[annotationId].push(event);
        });

        // Count annotations that have events and break it down by status
        Object.keys(annotationEvents).forEach((annotationId) => {
            const annotation = annotations[annotationId];

            if (annotation) {
                if (!numAnnotationEventsByStatus[annotation.status]) {
                    numAnnotationEventsByStatus[annotation.status] = 0;
                }
                numAnnotationEventsByStatus[annotation.status]++;
                numAnnotationEvents++;
            }
        });

        return {
            annotationEvents,
            numAnnotationEventsByStatus,
            numAnnotationEvents
        };
    }
);

export const getRevisionIdsThatHaveAnnotationEvents = createSelector(
    [getAnnotations, getAnnotationEvents],
    (annotations, events) => {
        const revisionIdsThatHaveEvents = [];

        Object.values(events).forEach((event) => {
            if (!event.data.annotationId) {
                return;
            }

            const annotation = annotations[event.data.annotationId];
            if (!annotation) {
                return;
            }

            if (annotation.CanvasRevisionId && !revisionIdsThatHaveEvents.includes(annotation.CanvasRevisionId)) {
                revisionIdsThatHaveEvents.push(annotation.CanvasRevisionId);
            }
        });

        return revisionIdsThatHaveEvents;
    }
);

const getCanvasId = (state) => state.ui.feedbackLink.canvasId;
const getCanvases = (state) => state.canvases;
const getDashboardCanvasIds = (state) => state.dashboardCanvases.ids;
const getAnnotationReplies = (state) => state.annotationReplies;
const getFilteredAnnotationIds = (state) => [...new Set(state.ui.feedbackLink.filteredAnnotationIds)];
const getCanvasAssetId = (state) => state.ui.feedbackLink.canvasAssetId;
const getCanvasRevisions = (state) => state.canvasRevisions;

export const getDashboardCanvases = createSelector(
    [getDashboardCanvasIds, getCanvases],
    (dashboardCanvasIds, canvases) => dashboardCanvasIds.map((id) => canvases[id])
);

export const getDashboardPremiumCanvases = createSelector(
    [getDashboardCanvasIds, getCanvases],
    (dashboardCanvasIds, canvases) => dashboardCanvasIds.map((id) => canvases[id]).filter((canvas) => canvas.isPremium)
);

export const getLatestRevisionForSelectedCanvas = createSelector(
    [getCanvasId, getCanvases, getCanvasRevisions],
    (canvasId, canvases, canvasRevisions) => {
        const canvas = canvases[canvasId];

        if (canvas?.CanvasRevisions.length > 0) {
            const [latestRevision] = canvas.CanvasRevisions.map((id) => canvasRevisions[id]).sort(
                (a, b) => b.number - a.number
            );

            return latestRevision;
        }

        return null;
    }
);

const getDashboardFolderIds = (state) => state.dashboardFolders.ids;
const getFolders = (state) => state.folders;
const getFoldersNestedList = (state) => state.ui.dashboard.foldersNestedList;

export const getDashboardFolders = createSelector([getDashboardFolderIds, getFolders], (dashboardFolderIds, folders) =>
    // Default to sorting folders alphabetically for display
    dashboardFolderIds
        .map((id) => folders[id])
        .sort((folderA, folderB) => {
            const folderANameCaseInsensitive = (folderA.name || '').toLowerCase();
            const folderBNameCaseInsensitive = (folderB.name || '').toLowerCase();
            if (folderANameCaseInsensitive < folderBNameCaseInsensitive) {
                return -1;
            }

            if (folderANameCaseInsensitive > folderBNameCaseInsensitive) {
                return 1;
            }

            return 0;
        })
);

export const getCanvasAnnotations = createSelector(
    [getCanvasId, getCanvases, getAnnotations, getUser, getSelectedRevisionId],
    (canvasId, canvases, annotations, user, selectedRevisionId) => {
        const canvas = canvases[canvasId];
        const shouldFilterByRevision = canvas?.CanvasRevisions.length > 0;

        if (!canvas) {
            return [];
        }

        return canvas.Annotations.map((id) => annotations[id]).filter((annotation) => {
            // Filter out private comments if the user isn't authorized to see them.
            if (annotation.isPrivate && !flags.userHasPrivateCommentsEnabled(user, canvas)) {
                return false;
            }

            // Filter out annotations that don't match the current revision
            if (shouldFilterByRevision && annotation.CanvasRevisionId !== selectedRevisionId) {
                return false;
            }

            return true;
        });
    }
);

export const getTopLevelFoldersByVisibility = createSelector([getFoldersNestedList], (foldersNestedList) => {
    const topLevelFoldersByVisibility = {
        'only-team': [],
        'anyone-with-link': [],
        'only-me': []
    };
    const foldersVisibility = Object.keys(topLevelFoldersByVisibility);

    foldersNestedList.forEach((folder) => {
        if (folder.parentFolderId) {
            return;
        }
        if (foldersVisibility.includes(folder.visibility)) {
            topLevelFoldersByVisibility[folder.visibility].push(folder);
        }
    });
    return topLevelFoldersByVisibility;
});

const getCanvasAssets = (state) => state.canvasAssets;

export const getCanvasAssetsToDisplay = createSelector(
    [getCanvasId, getCanvases, getCanvasAssets, getSelectedRevisionId],
    (canvasId, canvases, canvasAssets, selectedRevisionId) => {
        const canvas = canvases[canvasId];
        const allCanvasAssets = canvas?.CanvasAssets
            ? canvas.CanvasAssets.map((assetId) => canvasAssets[assetId]).sort((a, b) => a.position - b.position)
            : [];

        if (!selectedRevisionId) {
            return allCanvasAssets;
        }

        return allCanvasAssets.filter((canvasAsset) => canvasAsset.CanvasRevisionId === selectedRevisionId);
    }
);

export const isPDFDesignCanvas = createSelector(
    [getCanvasId, getCanvases, getCanvasAssets],
    (canvasId, canvases, canvasAssets) => {
        const canvas = canvases[canvasId];
        if (!canvas || canvas.type !== 'design') {
            return false;
        }

        const allCanvasAssets = canvas.CanvasAssets
            ? canvas.CanvasAssets.map((assetId) => canvasAssets[assetId]).sort((a, b) => a.position - b.position)
            : [];

        if (allCanvasAssets.length === 0) {
            return false;
        }

        return allCanvasAssets[0].type === 'pdf';
    }
);

export const getFilteredAnnotations = createSelector(
    [getFilteredAnnotationIds, getAnnotations],
    (annotationIds, annotations) => annotationIds.map((id) => annotations[id])
);

export const getAnnotationIdsByDevice = createSelector([getCanvasAnnotations], (annotations) => {
    const annotationIdsByDevice = {
        mobile: [],
        tablet: [],
        desktop: []
    };

    annotations.forEach((annotation) => {
        if (annotation?.metadata?.window?.width < 500) {
            annotationIdsByDevice.mobile.push(annotation.id);
        } else if (annotation?.metadata?.window?.width > 500 && annotation?.metadata?.window?.width <= 768) {
            annotationIdsByDevice.tablet.push(annotation.id);
        } else {
            annotationIdsByDevice.desktop.push(annotation.id);
        }
    });

    return annotationIdsByDevice;
});

export const getMentionedUserIds = createSelector(
    [getCanvasAnnotations, getAnnotationReplies],
    (annotations, annotationReplies) => {
        let allCommentsText = '';

        annotations.forEach((annotation) => {
            allCommentsText += annotation.comment;
            const replies = annotation.AnnotationReplies.map((id) => annotationReplies[id]);

            replies.forEach((reply) => {
                allCommentsText += reply.comment;
            });
        });

        const mentions = parseMentions(allCommentsText);

        return mentions.map((mention) => mention.id);
    }
);

const getSelectedAnnotationId = (state) => state.ui.feedbackLink.selectedAnnotationId;
const getFeedbackLinkShowCommentsByStatus = (state) => state.ui.feedbackLink.showCommentsByStatus;
const getSearchActive = (state) => state.ui.feedbackLink.searchActive;
const getFiltersActive = (state) => Object.keys(state.ui.feedbackLink.filters).length > 0;
const getSortBy = (state) => state.ui.feedbackLink.FeedbackSidebarAnnotationListSortBy;

export const getAnnotationsIdsInSidebarDisplayOrder = createSelector(
    [
        getFeedbackLinkShowCommentsByStatus,
        getCanvasAssets,
        getCanvasAnnotations,
        getFilteredAnnotations,
        getFiltersActive,
        getSearchActive,
        getSortBy
    ],
    (showCommentsByStatus, canvasAssets, annotations, filteredAnnotations, filtersActive, searchActive, sortBy) => {
        const allAnnotations = (searchActive || filtersActive
            ? filteredAnnotations
            : annotations
        ).filter((annotation) =>
            searchActive || filtersActive ? true : showCommentsByStatus.includes(annotation.status)
        );

        if (sortBy === 'recency') {
            return allAnnotations.map((annotation) => annotation.id);
        }

        // Sort by page
        const annotationsByPath = {};
        const sortedPaths = [];

        allAnnotations.forEach((annotation) => {
            const path = getAnnotationPath(annotation, canvasAssets);

            if (!annotationsByPath[path]) {
                sortedPaths.push(path);
                annotationsByPath[path] = [];
            }

            annotationsByPath[path].push(annotation);
        });

        const annotationIds = [];

        sortedPaths.forEach((path) => {
            annotationsByPath[path].forEach((annotation) => {
                annotationIds.push(annotation.id);
            });
        });

        return annotationIds;
    }
);

const getFeedbackLinkUsersPresent = (state) => state.ui.feedbackLink.usersPresent;

export const getUsersPresentOnCanvas = createSelector([getFeedbackLinkUsersPresent], (usersPresent) =>
    Object.values(usersPresent).sort((userA, userB) => userA.id - userB.id)
);

const getFeedbackLinkPath = (state) => state.ui.feedbackLink.path;
const getSelectedCanvasAssetPageIndex = (state) => state.ui.feedbackLink.canvasAssetPageIndex;

export const getAnnotationsToRenderAsPins = createSelector(
    [
        getCanvasId,
        getCanvases,
        getCanvasAssetId,
        getCanvasAnnotations,
        getFilteredAnnotations,
        getFeedbackLinkPath,
        getSelectedAnnotationId,
        getFeedbackLinkShowCommentsByStatus,
        getSelectedCanvasAssetPageIndex,
        getSearchActive,
        getFiltersActive
    ],
    (
        canvasId,
        canvases,
        canvasAssetId,
        annotations,
        filteredAnnotations,
        path,
        selectedAnnotationId,
        showCommentsByStatus,
        selectedCanvasAssetPageIndex,
        searchActive,
        filtersActive
    ) => {
        const canvas = canvases[canvasId];

        return (searchActive || filtersActive ? filteredAnnotations : annotations)
            .filter((annotation) => {
                if (canvas.type === 'design') {
                    return (
                        ((searchActive ? true : showCommentsByStatus.includes(annotation.status)) &&
                            annotation.CanvasAssetId === canvasAssetId &&
                            (annotation.canvasAssetPageIndex === null ||
                                annotation.canvasAssetPageIndex === selectedCanvasAssetPageIndex)) ||
                        selectedAnnotationId === annotation.id
                    );
                }

                // Website canvas
                // Note: We treat non-trailing slash page and trailing slash page the same
                return (
                    ((searchActive || filtersActive ? true : showCommentsByStatus.includes(annotation.status)) &&
                        pathsAreEqual(annotation?.path, path)) ||
                    selectedAnnotationId === annotation?.id
                );
            })
            .map((annotation) => ({
                ...annotation,
                selected: selectedAnnotationId === annotation.id
            }));
    }
);

export const getSelectedAnnotationEvents = createSelector(
    [getAnnotationEvents, getSelectedAnnotationId],
    (events, selectedAnnotationId) =>
        selectedAnnotationId
            ? Object.values(events).filter((event) => event.data.annotationId === selectedAnnotationId)
            : []
);

export const getSelectedAnnotationReplies = createSelector(
    [
        (state, annotationId) => annotationId || getSelectedAnnotationId(state),
        getAnnotations,
        getAnnotationReplies,
        getUser,
        getCanvasId,
        getCanvases
    ],
    (selectedAnnotationId, annotations, annotationReplies, user, canvasId, canvases) => {
        const canvas = canvases[canvasId];
        const annotation = selectedAnnotationId ? annotations[selectedAnnotationId] : null;
        return annotation && annotation.AnnotationReplies
            ? annotation.AnnotationReplies.map((id) => annotationReplies[id]).filter((reply) => {
                  // Filter out private replies if the user isn't authorized to see them.
                  if (reply.isPrivate && !flags.userHasPrivateCommentsEnabled(user, canvas)) {
                      return false;
                  }
                  return true;
              })
            : [];
    }
);

const getLabels = (state) => state.labels;

export const getSelectedAnnotationLabels = createSelector(
    [getSelectedAnnotationId, getAnnotations, getLabels],
    (selectedAnnotationId, annotations, labels) => {
        const annotation = selectedAnnotationId ? annotations[selectedAnnotationId] : null;
        return annotation && annotation.Labels
            ? annotation.Labels.map((id) => labels[id]).filter((label) => Boolean(label))
            : [];
    }
);

const getAllUsers = (state) => state.users;

export const getCanvasUsersToMention = createSelector(
    [(state, canvasId) => canvasId || getCanvasId(state), getCanvases, getAllUsers],
    (canvasId, canvases, users) => {
        const canvas = canvases[canvasId];
        const mentionsEnabled = Boolean(canvas.Customer && canvas.Customer.plan && canvas.Customer.plan.team);
        return mentionsEnabled ? Object.values(users) : [];
    }
);

export const getCanvasOwnerTeamMembers = createSelector(
    [(state, canvasId) => canvasId || getCanvasId(state), getCanvases, getAllUsers],
    (canvasId, canvases, users) => {
        const canvas = canvases[canvasId];
        const currentTeamId = canvas.User.TeamId;

        return Object.values(users).filter((user) => user.TeamId && user.TeamId === currentTeamId);
    }
);
